exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dresscode-convidados-index-js": () => import("./../../../src/pages/dresscode/convidados/index.js" /* webpackChunkName: "component---src-pages-dresscode-convidados-index-js" */),
  "component---src-pages-dresscode-index-js": () => import("./../../../src/pages/dresscode/index.js" /* webpackChunkName: "component---src-pages-dresscode-index-js" */),
  "component---src-pages-dresscode-madrinhas-index-js": () => import("./../../../src/pages/dresscode/madrinhas/index.js" /* webpackChunkName: "component---src-pages-dresscode-madrinhas-index-js" */),
  "component---src-pages-dresscode-padrinhos-index-js": () => import("./../../../src/pages/dresscode/padrinhos/index.js" /* webpackChunkName: "component---src-pages-dresscode-padrinhos-index-js" */),
  "component---src-pages-familia-js": () => import("./../../../src/pages/familia.js" /* webpackChunkName: "component---src-pages-familia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lista-de-presentes-js": () => import("./../../../src/pages/lista-de-presentes.js" /* webpackChunkName: "component---src-pages-lista-de-presentes-js" */),
  "component---src-pages-lua-de-mel-js": () => import("./../../../src/pages/lua-de-mel.js" /* webpackChunkName: "component---src-pages-lua-de-mel-js" */),
  "component---src-pages-nossa-historia-js": () => import("./../../../src/pages/nossa-historia.js" /* webpackChunkName: "component---src-pages-nossa-historia-js" */),
  "component---src-pages-padrinhos-e-madrinhas-js": () => import("./../../../src/pages/padrinhos-e-madrinhas.js" /* webpackChunkName: "component---src-pages-padrinhos-e-madrinhas-js" */),
  "component---src-pages-para-nossa-casa-js": () => import("./../../../src/pages/para-nossa-casa.js" /* webpackChunkName: "component---src-pages-para-nossa-casa-js" */)
}

